import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'select',
    'html',
    'image',
    'gallery',
    'video',
    'embed',
    'javascript',
  ];

  connect() {
    this.selected();
  }

  selected() {
    this.hideFields();
    switch (this.selectTarget.value) {
      case 'html':
        this.htmlTarget.classList.remove('d-none');
        break;
      case 'image':
        this.imageTarget.classList.remove('d-none');
        break;
      case 'gallery':
        this.galleryTarget.classList.remove('d-none');
        break;
      case 'video':
        this.videoTarget.classList.remove('d-none');
        break;
      case 'embed':
        this.embedTarget.classList.remove('d-none');
        break;
      case 'javascript':
        this.javascriptTarget.classList.remove('d-none');
        break;
    }
  }

  hideFields() {
    this.htmlTarget.classList.add('d-none');
    this.embedTarget.classList.add('d-none');
    this.javascriptTarget.classList.add('d-none');
    this.imageTarget.classList.add('d-none');
    this.galleryTarget.classList.add('d-none');
    this.videoTarget.classList.add('d-none');
  }
}
