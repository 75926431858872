import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['add_item', 'template'];

  addAssociation(event) {
    event.preventDefault();
    console.log('addAssociation');
    this.add_itemTarget.insertAdjacentHTML(
      'beforebegin',
      this.templateTarget.innerHTML
    );
  }

  /* eslint class-methods-use-this: ["error", { "exceptMethods": ["removeAssociation"] }] */
  removeAssociation(event) {
    event.preventDefault();
    console.log('removeAssociation');
    const item = event.target.closest('.nested-fields');
    item.querySelector('.destroy').value = null;
    item.style.display = 'none';
  }

  removeElement(event) {
    event.preventDefault();
    console.log('removeElement');
    const item = event.target.closest('.nested-fields');
    item.remove();
  }
}
